@import "~bootstrap/scss/bootstrap";
@import "mixins";
@import "custom";
@import "variables";
:root {
	--black: #090f11;
	--grey: #2d3234;
	--green: #60d702;
}
html {
	// font-size: calc(0.723vw + 0.0073px);
	font-size: calc(1.05vw + 0.0073px);

	height: 100vh;
	background-color: #090f11;
	color: #8b8f92;
	// font-size: 14px;
	//@media (min-width: 768px) {
	//	font-size: calc(0.723vw + 0.0073px);
	//
	//}
	@media (min-width: 1512px) {
		font-size: 16px;
	}
	
	@media (max-width: 767.9px) {
		font-size: calc(4.1007vw + 0.0073px);
		// font-size: calc(4.115vw + 0.0073px);
	}
}

#root {
	position: relative;
	font-family: $Inter;
	font-style: normal;
	background-color: #090f11;
	color: #8b8f92;
	overflow-x: hidden;
}

body {
	overflow: visible !important;
	height: auto !important;
}

a {
	text-decoration: none;
	color: #8b8f92;
}

iframe {
	max-height: 100%;
	overflow: visible;
}

h1,
.h1 {
	font-family: $Inter;
	font-size: rem(45);
	font-weight: 400;
	line-height: rem(60);
	letter-spacing: 0;
	text-align: left;
	color: #dadada;

	@media (max-width: 767.9px) {
		color: #dadada;
		font-size: rem(33);
		font-style: normal;
		font-weight: 400;
		line-height: rem(40);
	}
}

h2,
.h2 {
	font-family: $Inter;
	font-size: rem(35);
	font-weight: 400;
	line-height: rem(50);
	letter-spacing: 0.01rem;
	text-align: left;
	color: #dadada;

	@media (max-width: 767.9px) {
		color: #dadada;
		font-size: rem(22);
		font-style: normal;
		font-weight: 400;
		line-height: rem(32);
		letter-spacing: rem(0.22);
	}
}

.regular {
	font-family: $Inter;
	font-size: rem(24);
	font-weight: 400;
	line-height: rem(37);
	letter-spacing: 0.01rem;
	text-align: left;
	color: #8b8f92;

	@media (max-width: 767.9px) {
		color: #8b8f92;
		font-size: rem(16);
		font-style: normal;
		font-weight: 400;
		line-height: rem(25);
		letter-spacing: 0;
	}
}

.light {
	font-family: $Inter;
	font-size: rem(20);
	font-weight: 400;
	line-height: rem(30);
	letter-spacing: 0.01rem;
	text-align: left;
	color: #8b8f92;
}

.link {
	font-family: $RobotoMono;
	font-size: rem(16);
	font-style: normal;
	font-weight: 400;
	line-height: rem(28);
	text-transform: uppercase;
	color: #2d8cff;
	transition: all 0.25s ease;
	&:hover {
		color: #63a9fe;
	}

	@media (max-width: 767.9px) {
		color: #2d8cff;
		font-family: $RobotoMono;
		font-size: rem(13);
		font-style: normal;
		font-weight: 400;
		line-height: rem(20);
		letter-spacing: rem(1.95);
	}
}

.desktop {
	display: block;
	@media (max-width: 767.9px) {
		display: none;
	}
}

.mobile {
	display: none;
	@media (max-width: 767.9px) {
		display: block;
	}
}

.desktop-flex {
	display: flex;
	@media (max-width: 767.9px) {
		display: none;
	}
}

.mobile-flex {
	display: none;
	@media (max-width: 767.9px) {
		display: flex;
	}
}
