@import "mixins";
@import "custom";
@import "~bootstrap/scss/bootstrap";

.header {
    z-index: 99;
    position: fixed;
    width: 100%;
    font-family: $RobotoMono;
    font-size: rem(16);
    font-weight: 400;
    line-height: rem(28);
    letter-spacing: 0.1rem;
    text-align: left;
    text-transform: uppercase;
    backdrop-filter: blur(0.625rem);
    @media (max-width: 767.9px) {
        position: relative;
        color: #dadada;
        font-size: rem(13);
        font-style: normal;
        font-weight: 400;
        line-height: rem(20);
        letter-spacing: rem(1.95);
    }
    .container {
        max-width: rem(1006);
        padding: 0;
        @media (max-width: 767.9px) {
            padding: 0 rem(30);
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(16) 0;
        @media (max-width: 767.9px) {
            padding: rem(30) 0;
        }
    }

    &__desktop {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: rem(16) 0;

        @media (max-width: 767.9px) {
            display: none;
        }
    }

    &__mobile {
        display: none;

        @media (max-width: 767.9px) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &__links {
        display: flex;
        align-items: center;
        gap: rem(20);
        @media (max-width: 767.9px) {
            margin-bottom: rem(20);
        }
    }

    &__logo-link {
        display: flex;
        align-items: center;
        gap: rem(20);
        cursor: pointer;

        p {
            margin-bottom: 0;
            transition: all 0.25s ease;
        }

        &:hover {
            p {
                color: #dadada;
                @media (max-width: 767.9px) {
                    color: #71ff00;
                }
            }

            .header__logo {
                filter: brightness(1.5);
            }
        }
    }

    &__logo {
        width: rem(52);
        height: rem(40);
        transition: all 0.25s ease;
        img {
            width: 100%;
            height: auto;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        gap: rem(30);
        @media (max-width: 767.9px) {
            justify-content: space-between;
            width: 100%;
            .header__nav-item {
                color: #8b8f92;
            }
        }

        &-item {
            transition: all 0.25s ease;
            color: #8b8f92;
            &:hover {
                color: #dadada;
                @media (max-width: 767.9px) {
                    color: #71ff00;
                }
            }

            @media (max-width: 767.9px) {
                white-space: nowrap;
                color: #dadada;

                &:hover {
                    color: #71ff00;
                }
            }
        }
    }

    &__anchor {
        display: flex;
        align-items: center;
        gap: rem(30);

        &-item {
            color: #60d702;
            transition: all 0.25s ease;
            &:hover {
                color: #71ff00;
            }
            @media (max-width: 767.9px) {
                color: #8b8f92;
            }
        }

        @media (max-width: 767.9px) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: rem(13);
        }
    }

    &__divider {
        width: 100%;
        height: rem(3);
        border-bottom: rem(1) solid #8b8f92;
    }
}

.main {
    position: relative;
    .container {
        max-width: rem(1006);
        padding: rem(208) 0 0;
        @media (max-width: 767.9px) {
            padding: rem(136) rem(30) 0;
        }
    }

    &__wrapper {
        @media (max-width: 767.9px) {
            padding: rem(376) 0 0;
        }
    }

    &__block {
        max-width: rem(590);
        margin: 0 auto rem(187);
        @media (max-width: 767.9px) {
            margin: 0 auto rem(75);
        }
    }

    &__block.info {
        max-width: 100%;
        margin: 0 auto rem(150);
        .info__row {
            margin-bottom: rem(20);
            &-1 {
                display: flex;
                justify-content: space-between;
                gap: rem(20);

                @media (max-width: 767.9px) {
                    flex-wrap: wrap;
                    margin-left: rem(-15);
                    width: calc(100% + rem(30));
                    gap: rem(10);
                }

                .info__row-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    border-radius: rem(40);
                    border: rem(3) solid var(--grey);
                    backdrop-filter: blur(rem(10));
                    padding: rem(45) rem(25);

                    font-family: $RobotoMono;
                    font-size: rem(20);
                    font-weight: 400;
                    line-height: rem(30);
                    letter-spacing: 0.15rem;
                    text-align: center;
                    text-transform: uppercase;

                    @media (max-width: 767.9px) {
                        border-radius: rem(24);
                        width: rem(173);
                        padding: rem(20) rem(25);
                        color: #8b8f92;
                        text-align: center;
                        font-family: $RobotoMono;
                        font-size: rem(14);
                        font-style: normal;
                        font-weight: 400;
                        line-height: rem(21);
                        letter-spacing: rem(2.1);
                        text-transform: uppercase;
                    }
                }

                .green-text {
                    color: var(--green);
                }

                .green-block {
                    background-color: var(--green);
                    color: var(--black);
                    border: rem(3) solid var(--green);
                    transition: all 0.25s ease;

                    &:hover {
                        background-color: #71ff00;
                        border: rem(3) solid #71ff00;
                    }
                }
            }

            &-2 {
                display: flex;
                justify-content: space-between;
                gap: rem(20);
                @media (max-width: 767.9px) {
                    flex-direction: column;
                    margin-left: rem(-15);
                    width: calc(100% + rem(30));
                    gap: rem(10);
                }
                .info__row-item {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 100%;
                    border-radius: rem(40);
                    border: rem(3) solid var(--grey);
                    backdrop-filter: blur(rem(10));
                    padding: rem(50);
                    @media (max-width: 767.9px) {
                        border-radius: rem(24);
                        padding: rem(25) rem(30);
                        flex-direction: row;
                        gap: rem(20);
                    }
                    h2 {
                        margin-bottom: rem(50);
                        @media (max-width: 767.9px) {
                            margin-bottom: rem(20);
                        }
                    }

                    p {
                        margin-bottom: 0;
                        font-size: rem(20);
                        font-weight: 400;
                        line-height: rem(30);
                        letter-spacing: 0.01rem;
                        text-align: left;

                        @media (max-width: 767.9px) {
                            color: #8b8f92;
                            font-size: rem(16);
                            font-style: normal;
                            font-weight: 400;
                            line-height: rem(25);
                            letter-spacing: 0;
                        }
                    }

                    img {
                        margin-bottom: rem(58);
                        width: rem(66);
                        height: rem(66);
                        @media (max-width: 767.9px) {
                            order: 1;
                            margin-bottom: 0;
                            width: rem(54);
                            height: rem(54);
                        }
                    }
                }
                .large {
                    min-width: rem(600);
                    @media (max-width: 767.9px) {
                        min-width: auto;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        gap: rem(20);
                    }
                }
            }
        }
    }

    &__block.section-3 {
        position: relative;
        // max-width: rem(520);
        max-width: 100%;
        padding: 0 rem(245);
        margin: 0 auto rem(150);
        @media (max-width: 767.9px) {
            padding: 0 rem(15);
            margin: 0 auto rem(75);
        }
        .main__title {
            @media (max-width: 767.9px) {
                color: #dadada;
                font-size: rem(25);
                font-style: normal;
                font-weight: 400;
                line-height: rem(35); /* 140% */
                letter-spacing: rem(0.25);
            }
        }
        .section-3 {
            &__link {
                position: absolute;
                top: rem(-147);
                left: rem(0);
                @media (max-width: 767.9px) {
                    width: 100%;
                }
                &:hover {
                    .section-3__link-1 {
                        opacity: 0;
                        visibility: hidden;
                    }

                    .section-3__link-2 {
                        opacity: 1;
                        visibility: visible;
                    }
                    .section-3__link-text {
                        color: #63a9fe;
                    }
                }

                &-img {
                }

                &-1 {
                    width: rem(91);
                    height: rem(91);
                    position: absolute;
                    top: rem(19);
                    left: rem(3);
                    opacity: 1;
                    visibility: visible;

                    transition: all 0.35s ease;
                }

                &-2 {
                    width: rem(110);
                    height: rem(104);
                    position: absolute;
                    top: rem(19);
                    left: rem(-4);

                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.35s ease;
                }

                &-3 {
                    width: rem(56);
                    height: rem(56);
                    position: absolute;
                    top: rem(40);
                    right: rem(15);
                    opacity: 1;
                    visibility: visible;

                    transition: all 0.35s ease;
                }

                &-text {
                    position: absolute;
                    top: rem(110);
                    left: rem(55);
                    width: rem(116);
                    transition: all 0.35s ease;

                    @media (max-width: 767.9px) {
                        top: rem(50);
                        left: rem(15);
                        width: rem(150);
                    }
                }
            }
        }
    }

    &__block.plan {
        max-width: 100%;
        margin: 0 auto rem(150);
        @media (max-width: 767.9px) {
            margin: 0 auto rem(75) rem(-15);
            max-width: fit-content;
            width: calc(100% + rem(30));
        }
        .plan {
            &__wrapper {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: rem(-140);
                    left: rem(600);
                    z-index: 3;
                    display: block;
                    background-image: url("../../../public/images/code@2x.png");
                    background-size: contain;
                    width: rem(245);
                    height: rem(205);
                }
                .screen {
                    overflow: visible;
                    position: relative;
                    height: 100%;
                    max-height: 100%;
                    min-height: rem(612);
                    display: flex;
                    flex-direction: column;
                    transform: rotate(0deg);
                    border-radius: rem(40);
                    border: rem(3) solid var(--grey);
                    backdrop-filter: blur(rem(10));
                    margin-top: rem(-3);
                    padding: rem(50);

                    background-color: #1d2325;

                    @media (max-width: 767.9px) {
                        border-radius: rem(24);
                        width: 100%;
                        padding: rem(23) rem(28) rem(0) rem(28);
                        min-height: rem(210);
                        margin-bottom: rem(500);
                    }

                    .info__row.info__row-1 {
                        width: 100%;
                        margin-bottom: rem(50);
                        @media (max-width: 767.9px) {
                            margin-bottom: rem(10);
                        }

                        .screen__col {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;

                            h2 {
                                width: rem(367);
                                @media (max-width: 767.9px) {
                                    font-size: rem(20);
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: rem(28);
                                    letter-spacing: rem(0.2);
                                }
                            }
                            span {
                                margin-bottom: 0;
                                font-family: $RobotoMono;
                                font-size: rem(16);
                                font-weight: 400;
                                line-height: rem(30);
                                letter-spacing: 0.15rem;
                                text-align: right;
                                text-transform: uppercase;
                                white-space: nowrap;
                                @media (max-width: 767.9px) {
                                    color: #8b8f92;
                                    font-size: rem(10);
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: rem(20);
                                    letter-spacing: rem(1.5);
                                    text-transform: uppercase;
                                    margin-right: rem(-16);
                                }
                            }
                        }
                    }

                    .info__row.info__row-2 {
                        .screen__col {
                            .screenshot__container {
                                position: absolute;
                                top: rem(150);
                                right: rem(-50);
                                @media (max-width: 767.9px) {
                                    top: rem(230);
                                    right: 0;
                                }
                            }

                            span {
                                font-size: rem(20);
                                font-weight: 400;
                                line-height: rem(30);
                                letter-spacing: 0.01rem;
                                text-align: left;

                                @media (max-width: 767.9px) {
                                    color: #8b8f92;
                                    font-size: rem(16);
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: rem(25);
                                }
                            }

                            p {
                                font-family: $RobotoMono;
                                font-size: rem(16);
                                font-weight: 500;
                                line-height: rem(28);
                                letter-spacing: 0.15rem;
                                text-align: left;
                                text-transform: uppercase;
                                margin-bottom: 0;
                                color: #090f11;
                            }
                        }
                    }
                }
            }

            &__item {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                // max-height: 150px;
                border-radius: rem(40);
                border: rem(3) solid var(--grey);
                backdrop-filter: blur(rem(10));
                margin-top: rem(-3);
                padding: rem(50) rem(50) rem(0) rem(50);
                transition: all 0.25s ease;
                cursor: pointer;
                @media (max-width: 767.9px) {
                    border-radius: rem(24);
                    width: 100%;
                    padding: rem(23) rem(28) rem(0) rem(28);
                }
                &:hover {
                    background-color: #1d2325;
                    @media (max-width: 767.9px) {
                        background-color: transparent;
                    }
                }
            }

            &__item.large {
                max-height: rem(200);
            }
            &__item.first {
                .plan__row.plan__row-2.first {
                    max-height: rem(2000);
                    padding-bottom: rem(50);
                }
            }
            &__item.active.first {
                .plan__row.plan__row-2.active.first {
                    max-height: 0;
                    overflow: hidden;
                    padding-bottom: 0;
                }
            }

            &__row {
                &-1 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: rem(50);
                    @media (max-width: 767.9px) {
                        align-items: flex-start;
                        margin-bottom: rem(20);
                    }
                    h2 {
                        margin-bottom: 0;
                        @media (max-width: 767.9px) {
                            color: #dadada;
                            font-size: rem(20);
                            font-style: normal;
                            font-weight: 400;
                            line-height: rem(28);
                            letter-spacing: rem(0.2);
                        }
                    }

                    span {
                        margin-bottom: 0;
                        font-family: $RobotoMono;
                        font-size: rem(16);
                        font-weight: 400;
                        line-height: rem(30);
                        letter-spacing: 0.15rem;
                        text-align: right;
                        text-transform: uppercase;
                        white-space: nowrap;

                        @media (max-width: 767.9px) {
                            color: #8b8f92;
                            font-size: rem(10);
                            font-style: normal;
                            font-weight: 400;
                            line-height: rem(20);
                            letter-spacing: rem(1.5);
                            text-transform: uppercase;
                        }
                    }
                }

                &-2 {
                    display: flex;
                    flex-direction: row;
                    gap: rem(30);
                    max-height: 0;
                    overflow: hidden;
                    padding-bottom: 0;
                    @media (max-width: 767.9px) {
                        flex-direction: column;
                    }
                    .plan__column {
                        font-size: rem(20);
                        font-weight: 400;
                        line-height: rem(30);
                        letter-spacing: 0.01rem;
                        text-align: left;
                        max-width: rem(435);
                        @media (max-width: 767.9px) {
                            font-size: rem(16);
                            font-style: normal;
                            font-weight: 400;
                            line-height: rem(25);
                        }
                        span {
                            display: block;
                            color: #dadada;
                            margin-bottom: rem(5);
                        }

                        p {
                            color: #8b8f92;
                            margin-bottom: 0;
                        }
                    }
                }

                &-2.active {
                    max-height: rem(2000);
                    padding-bottom: rem(50);
                }
                // &-2.first {
                //     max-height: rem(2000);
                //     padding-bottom: rem(50);
                // }
                // &-2.active.first {
                //     max-height: 0;
                //     padding-bottom: 0;
                // }
            }
        }
    }

    &__block.section-5 {
        display: flex;
        align-items: flex-end;
        max-width: rem(640);
        margin: 0 auto rem(150);
        @media (max-width: 767.9px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 rem(15);
            margin: 0 auto rem(40);
        }
        .main__title {
            margin-bottom: 0;
            @media (max-width: 767.9px) {
                margin-bottom: rem(50);
            }
        }

        a {
            min-width: rem(110);
            padding-bottom: rem(6);
            @media (max-width: 767.9px) {
                max-width: rem(145);
            }
        }

        h1 {
            @media (max-width: 767.9px) {
                color: #dadada;
                font-size: rem(25);
                font-style: normal;
                font-weight: 400;
                line-height: rem(35);
                letter-spacing: rem(0.25);
            }
        }
    }

    &__block.feedbacks {
        max-width: rem(960);
        margin: 0 auto rem(150);
        @media (max-width: 767.9px) {
            width: calc(100% + rem(60));
            margin: 0 auto rem(75) rem(-30);
        }

        .feedbacks__wrapper {
            position: relative;
        }
        .feedbacks__stickers {
            &-item {
                position: absolute;
                @media (max-width: 767.9px) {
                    display: none;
                }
                img {
                    width: 100%;
                    height: auto;
                }

                &:nth-child(1) {
                    right: rem(-70);
                    top: rem(-28);
                    width: rem(134);
                    height: rem(134);
                }

                &:nth-child(2) {
                    left: rem(-135);
                    top: rem(830);
                    width: rem(155);
                    height: rem(117);
                }

                &:nth-child(3) {
                    right: rem(-70);
                    top: rem(1250);
                    width: rem(110);
                    height: rem(110);
                }

                &:nth-child(4) {
                    left: rem(-155);
                    top: rem(2030);
                    width: rem(174);
                    height: rem(172);
                }

                &:nth-child(5) {
                    right: rem(-165);
                    top: rem(2700);
                    width: rem(221);
                    height: rem(143);
                }

                &:nth-child(6) {
                    left: rem(-105);
                    top: rem(3540);
                    width: rem(123);
                    height: rem(113);
                }
            }
        }
        .feedbacks__container {
            max-height: rem(1050);
            overflow: hidden;
        }
        .feedbacks__container.active {
            max-height: rem(999999999);
        }
        .feedbacks__list {
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // grid-auto-rows: 5px;
            // grid-auto-flow: row dense;
            display: flex;
            justify-content: space-between;
            gap: rem(30);
            // flex-wrap: wrap;
            // gap: rem(30);
            // max-height: rem(1050);
            // overflow: hidden;

            // columns: 2;
            // column-gap: rem(30);

            @media (max-width: 767.9px) {
                columns: auto;
                display: flex;
                gap: rem(10);
            }
        }

        .feedbacks__list.active {
            // max-height: rem(999999);
        }

        .feedbacks__button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: rem(3) solid #2d3234;
            height: rem(87);
            cursor: pointer;
            transition: all 0.25s ease;
            @media (max-width: 767.9px) {
                display: none;
            }
        }

        .feedbacks__button:hover {
            color: #63a9fe;
            background-color: #1d2325;
        }
        .gutter-sizer {
            width: rem(30);
        }
        .feedback {
            &__item {
                // height: fit-content;
                max-width: rem(465);
                padding: rem(37);
                border-radius: rem(40) rem(40) rem(40) 0;
                border: rem(3) solid #2d3234;
                margin-bottom: rem(30);

                // &:nth-child(1) {
                //     grid-row-start: 1;
                //     grid-row-end: 3;
                // }

                // &:nth-child(4) {
                //     top: 505px;
                // }

                // &:nth-child(5) {
                //     grid-row-start: 1;
                //     grid-row-end: 3;
                // }
                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: 767.9px) {
                    min-width: rem(320);
                    max-width: calc(100% - rem(60));
                    padding: rem(23) rem(28);
                    // margin-left: rem(30);
                    border-radius: rem(20) rem(20) rem(20) 0;

                    // &:first-child {
                    //     margin-left: rem(30);
                    // }
                }
            }
            &__text {
                margin-bottom: rem(32);

                color: #8b8f92;
                font-size: rem(20);
                font-style: normal;
                font-weight: 400;
                line-height: rem(30); /* 150% */
                letter-spacing: rem(0.2);

                @media (max-width: 767.9px) {
                    color: #8b8f92;
                    font-size: rem(16);
                    font-style: normal;
                    font-weight: 400;
                    line-height: rem(25);
                }
            }

            &__img {
                width: rem(50);
                height: rem(50);
                border-radius: 50%;
                img {
                    width: 100%;
                    height: auto;
                }
            }

            &__name {
                margin-top: rem(2);
                margin-bottom: rem(5);

                color: #dadada;
                font-family: $RobotoMono;
                font-size: rem(13);
                font-style: normal;
                font-weight: 400;
                line-height: rem(20); /* 153.846% */
                letter-spacing: rem(1.95);
                text-transform: uppercase;
            }

            &__post {
                color: #8b8f92;
                font-family: $RobotoMono;
                font-size: rem(13);
                font-style: normal;
                font-weight: 400;
                line-height: rem(20); /* 153.846% */
                letter-spacing: rem(1.95);
                text-transform: uppercase;
            }

            &__row {
                &-1 {
                }

                &-2 {
                    display: flex;
                    gap: rem(20);
                }
            }

            &__column {
            }
        }
    }

    &__block.form {
        max-width: 100%;
        min-height: rem(600);
        margin: 0 auto rem(150);
        @media (max-width: 1199.9px) {
            max-width: initial;
            margin: 0 auto rem(75);
            width: 100%;
        }
        @media (max-width: 450px) {
            margin: 0 auto rem(75) rem(-30);
            width: calc(100% + rem(60));
        }
        .form {
            &__container {
                position: relative;
                display: flex;
                justify-content: space-between;
                // gap: 175px;
                padding: rem(48);
                border: rem(3) solid #2d3234;
                border-radius: rem(40);

                @media (max-width: 767.9px) {
                    border: none;
                    padding: 0;
                }
            }

            &__element {
                position: relative;
                width: 100%;
                max-width: 513px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: rem(10);
                    background-color: #090f11;
                    border-radius: 0;
                }
                @media (max-width: 1199.9px) {
                    max-width: 100%;
                }
                iframe {
                    .form {
                        &-content {
                            color: #dadada;
                            font-family: $Inter;
                        }

                        &__title {
                            p {
                                font-size: rem(45);
                                font-style: normal;
                                font-weight: 400;
                                line-height: rem(60); /* 133.333% */
                                text-align: left;
                            }
                        }
                    }
                }
            }

            &__info {
                width: rem(212);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media (max-width: 767.9px) {
                    display: none;
                }
                @media (max-width: 1199.9px) {
                    display: none;
                }
                &-text {
                    padding-top: rem(125);

                    p {
                        margin-bottom: rem(9);
                        color: var(--text-grey, #8b8f92);
                        font-family: $RobotoMono;
                        font-size: rem(16);
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                &-link {
                    margin-bottom: rem(75);
                    img {
                        width: rem(126);
                        height: rem(135);
                        margin-left: rem(56);
                    }

                    a {
                        width: rem(106);
                        display: block;
                    }
                }
            }

            &-popover {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: rem(250);
                opacity: 0;
                visibility: hidden;
                z-index: -1;
                position: absolute;
                bottom: rem(260);
                right: rem(165);
                padding: rem(16) rem(22);
                border-radius: 40px;
                border: 3px solid var(--grey-border, #2d3234);
                backdrop-filter: blur(10px);
                transform: rotate(348deg);
                transition: all 0.25s ease;
            }
            &-popover.show {
                opacity: 1;
                visibility: visible;
                z-index: 1;
            }
        }
    }

    &__title {
        margin-bottom: rem(50);
    }

    &__text {
    }

    &__bg {
        &:hover {
            .main__bg-1 {
                opacity: 0;
                visibility: hidden;
            }

            .main__bg-2 {
                opacity: 1;
                visibility: visible;
            }

            @media (max-width: 767.9px) {
                .main__bg-1 {
                    opacity: 1;
                    visibility: visible;
                }

                .main__bg-2 {
                    display: none;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &-1 {
            position: absolute;
            top: rem(93);
            right: 0;
            width: rem(372);
            height: rem(639);
            clip-path: polygon(9% 7%, 100% 0%, 100% 100%, 13% 96%);

            @media (max-width: 767.9px) {
                clip-path: none;
                position: absolute;
                top: rem(47);
                left: auto;
                right: 0;
                width: rem(269);
                height: rem(490);
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &-2 {
            position: absolute;
            top: rem(93);
            right: 0;
            width: rem(372);
            height: rem(639);
            opacity: 0;
            visibility: hidden;
            clip-path: polygon(9% 7%, 100% 0%, 100% 100%, 13% 96%);

            img {
                width: 100%;
                height: auto;
            }
        }

        &-text {
            position: absolute;
            top: rem(618);
            right: rem(172);
            transform: rotate(16.68deg);
            width: rem(190);
            font-family: $RobotoMono;
            font-size: rem(13);
            font-weight: 400;
            line-height: rem(24);
            letter-spacing: 0.15rem;
            text-align: center;
            text-transform: uppercase;
            color: #8b8f92;
        }
    }
}

.screenshot {
    // transform: rotate(3deg);
    width: rem(600);
    height: rem(608);
    position: relative;
    @media (max-width: 767.9px) {
        width: 22.5rem;
        height: 23rem;
    }

    &__wrapper {
        width: 27rem;
        height: 30.25rem;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #cfb2a3;
        @media (max-width: 767.9px) {
            width: 18rem;
            height: 16.25rem;
        }
    }

    &__mask {
        z-index: 1;
        position: absolute;
        top: rem(-60);

        width: rem(622);
        height: rem(639);
        img {
            width: 100%;
            height: auto;
        }

        @media (max-width: 767.9px) {
            width: rem(345);
            height: rem(349);
            transform: rotate(-1deg);
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    // &::before {
    //     content: none;
    //     z-index: 1;
    //     position: absolute;
    //     top: rem(-60);
    //     width: 100%;
    //     height: 100%;
    //     background-image: url("../../../public/images/Subtract.png");
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     @media (max-width: 767.9px) {
    //         transform: rotate(-1deg);
    //     }
    // }
    .video-container {
        // transform: rotateY(-180deg) rotate(3deg) translateZ(0);
        margin-left: 6rem;
        margin-top: rem(-220);
        transform: rotateY(-180deg) rotate(3deg) translateZ(0);
        @media (max-width: 767.9px) {
            margin-left: 0;
            margin-top: rem(-220);
            transform: rotateY(-180deg) rotate(3deg) translateZ(0);
        }
        // margin-right: rem(-75);
        // width: rem(1000);
        // height: rem(720);
        // @media (max-width: 767.9px) {
        //     transform: rotateY(-180deg) rotate(3deg) translateZ(0);
        //     height: 25rem;
        //     margin-left: 12rem;
        //     margin-right: auto;
        //     margin-top: -7.75rem;
        //     width: 27.5rem;
        // }

        video {
            width: 65.6875rem;
            height: 40.125rem;
            @media (max-width: 767.9px) {
                width: rem(475);
                height: rem(450);
            }
        }
    }

    button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: rem(10);

        width: fit-content;
        height: rem(48);
        padding: rem(10) rem(26) rem(10) rem(26);
        border-radius: rem(50);
        border: none;
        gap: rem(10);

        background-color: #60d702;
        transition: all 0.25s ease;

        &:hover {
            background-color: #71ff00;
        }
        svg {
            width: rem(28);
            height: rem(28);
        }

        p {
            font-family: $RobotoMono;
            font-size: rem(16);
            font-weight: 500;
            line-height: rem(28);
            letter-spacing: 0.15rem;
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }

    .open-cam {
        left: rem(-400);
        bottom: rem(270);
        @media (max-width: 767.9px) {
            left: rem(55);
            bottom: rem(10);
        }
    }

    .photo {
        left: rem(-400);
        bottom: rem(200);
        opacity: 1;
        pointer-events: initial;
        user-select: initial;
        transition: all 0.25s ease;
        @media (max-width: 767.9px) {
            left: rem(55);
            bottom: rem(-60);
        }
    }

    .photo.disabled {
        opacity: 0.7;
        pointer-events: none;
        user-select: none;
    }

    &__img {
        transform: rotateY(-180deg) rotate(3deg);
        position: absolute;
        // width: 62.5rem;
        top: -10.4rem;
        left: -11.15rem;
        clip-path: polygon(27.5% 30.5%, 76% 22%, 80.75% 100%, 31% 100%);
        img {
            width: 65.75rem;
            height: auto;
        }
        @media (max-width: 767.9px) {
            position: relative;
            clip-path: none;
            // left: -3.6rem;
            // top: -2.85rem;
            top: -8.85rem;
            left: -10.15rem;
            margin-left: 10.3rem;
            margin-top: -0.5rem;
            width: 25.5em;
            height: 25rem;
            // height: auto;
            // width: 475px;
            // height: 450px;
            img {
                position: relative;
                height: auto;
                width: 15.7rem;
            }
        }
    }
}

.screenshot-test {
    // transform: rotate(3deg);
    width: rem(600);
    height: rem(608);
    position: relative;
    @media (max-width: 767.9px) {
        width: 22.5rem;
        height: 23rem;
    }

    .screenshot__wrapper {
        border: 1px solid red;
        width: 27rem;
        height: 30.25rem;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        // align-items: center;
        justify-content: center;
        background-color: transparent;
        @media (max-width: 767.9px) {
            width: 19rem;
            height: 17.25rem;
        }
    }
    &::before {
        content: none;
        z-index: 1;
        position: absolute;
        top: rem(-60);
        width: 100%;
        height: 100%;
        background-image: url("../../../public/images/Subtract.png");
        background-size: cover;
        background-repeat: no-repeat;
        // @media (max-width: 767.9px) {
        //     transform: rotate(-1deg);
        // }
    }
    video {
        position: fixed;
        top: 0;
        transform: rotateY(-180deg) rotate(3deg);
        margin-top: rem(-220);
        margin-right: rem(-75);
        width: rem(1000);
        height: rem(720);

        @media (max-width: 767.9px) {
            height: 40rem;
            margin-right: -2.6875rem;
            margin-top: -13.75rem;
            transform: rotateY(-180deg) rotate(3deg) translateZ(0);
            width: 32.5rem;
        }
    }

    button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: rem(10);

        width: fit-content;
        height: rem(48);
        padding: rem(10) rem(26) rem(10) rem(26);
        border-radius: rem(50);
        border: none;
        gap: rem(10);

        background-color: #60d702;

        svg {
            width: rem(28);
            height: rem(28);
        }

        p {
            font-family: $RobotoMono;
            font-size: rem(16);
            font-weight: 500;
            line-height: rem(28);
            letter-spacing: 0.15rem;
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }

    .open-cam {
        left: rem(-400);
        bottom: rem(270);
        @media (max-width: 767.9px) {
            left: rem(55);
            bottom: rem(10);
        }
    }

    .photo {
        left: rem(-400);
        bottom: rem(200);
        opacity: 1;
        pointer-events: initial;
        user-select: initial;
        transition: all 0.25s ease;
        @media (max-width: 767.9px) {
            left: rem(55);
            bottom: rem(-60);
        }
    }

    .photo.disabled {
        opacity: 0.7;
        pointer-events: none;
        user-select: none;
    }

    &__img {
        transform: rotateY(-180deg) rotate(3deg);
        position: absolute;
        width: 62.5rem;
        top: -8.85rem;
        left: -10.15rem;
        clip-path: polygon(27.5% 27.5%, 76% 18%, 80.75% 100%, 31% 100%);
        img {
            width: 100%;
            height: auto;
        }
        @media (max-width: 767.9px) {
            position: relative;
            clip-path: none;
            // left: -3.6rem;
            // top: -2.85rem;
            margin-left: 11.115rem;
            margin-top: -4.9rem;
            width: 32.5em;
            height: auto;
            img {
                position: relative;
                height: auto;
                width: 22.5rem;
            }
        }
    }
}

.about {
    position: relative;
    overflow: hidden;
    .form__element {
        display: none;
    }

    .header {
        .container {
            max-width: 62.875rem;
            padding: 0;
            @media (max-width: 767.9px) {
                padding: 0 rem(30);
            }
        }
    }

    .container {
        max-width: rem(1006);
        padding: rem(222) 0 0;
        @media (max-width: 767.9px) {
            padding: 0 rem(30);
        }
    }

    &__wrapper {
        @media (max-width: 767.9px) {
            padding: rem(430) 0 0;
        }
    }

    &__block {
        max-width: rem(590);
        margin: 0 auto rem(150);
        @media (max-width: 767.9px) {
            margin: 0 auto rem(75);
        }

        &-large {
            .about__title {
                margin-bottom: 0;
            }

            .about__text-large {
                font-size: rem(250);
                font-weight: 400;
                line-height: rem(147);
                margin-left: -6rem;
                letter-spacing: 0.005rem;
                text-align: center;
                color: #dadada;
                transition: all 0.25s ease;

                &:hover {
                    text-shadow: 0px 0px 100px rgba(255, 255, 255, 0.5);
                }

                @media (max-width: 767.9px) {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    margin-left: rem(50);

                    color: #dadada;
                    font-size: rem(217);
                    font-style: normal;
                    font-weight: 100;
                    line-height: rem(157);
                    letter-spacing: rem(1.085);
                    text-align: left;
                    &:hover {
                    }
                }

                &_icons {
                    display: none;
                    @media (max-width: 767.9px) {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        right: rem(26);
                        bottom: rem(168);
                        img:nth-child(1) {
                            width: rem(44);
                            height: rem(73);
                            margin-left: rem(2);
                        }

                        img:nth-child(2) {
                            width: rem(90);
                            height: rem(27);
                        }
                    }
                }
            }
            @media (max-width: 767.9px) {
                margin: 0 auto rem(150);
            }
        }
    }

    .large-mobile {
        @media (max-width: 767.9px) {
            .about__title {
                max-width: rem(206);
                margin-bottom: rem(705);
            }
        }
    }

    &__title {
        margin-bottom: rem(50);
        @media (max-width: 767.9px) {
            margin-bottom: rem(30);
        }
    }

    &__text {
    }

    &__questions {
        display: block;
        border: rem(3) solid #2d3234;
        border-radius: rem(40);
        margin-bottom: rem(135);
        transition: all 0.25s ease;
        cursor: pointer;

        @media (max-width: 767.9px) {
            display: none;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(60);
            padding: rem(40) rem(66) rem(28) rem(50);

            svg {
                width: rem(660);
                height: rem(80);

                path {
                    transition: all 0.25s ease;
                }
            }

            p {
                transition: all 0.25s ease;
                color: #2d8cff;
            }
        }
    }
    &__questions:hover {
        background-color: #1d2325;

        p {
            color: #63a9fe;
        }

        svg {
            path:nth-child(1) {
                fill: #63a9fe;
            }

            path:nth-child(2) {
                stroke: #63a9fe;
            }

            path:nth-child(3) {
                stroke: #63a9fe;
            }
        }
    }

    &-bg-1 {
        position: absolute;
        top: rem(93);
        left: 0;
        width: rem(416);
        height: rem(639);
        @media (max-width: 767.9px) {
            position: absolute;
            top: rem(40);
            left: auto;
            right: 0;
            width: rem(323);
            height: rem(500);
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-bg-2 {
        position: absolute;
        bottom: rem(672);
        right: 0;
        width: rem(442);
        height: rem(1305);

        @media (max-width: 767.9px) {
            position: absolute;
            bottom: rem(1050);
            left: auto;
            right: 0;
            width: rem(264);
            height: rem(965);
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

.politics {
    .container {
        max-width: rem(740);
        margin: 0 auto;
        padding: rem(72) 0 0;
        @media (max-width: 767.9px) {
            padding: 0 rem(30);
        }
    }

    &__wrapper {
        padding-top: rem(145);
        @media (max-width: 767.9px) {
            padding-top: rem(90);
        }
    }

    &__block {
        margin-bottom: rem(145);
        color: #8b8f92;
        font-size: rem(20);
        font-style: normal;
        font-weight: 400;
        line-height: rem(30);
        letter-spacing: rem(0.2);
        @media (max-width: 767.9px) {
            margin-bottom: rem(75);
            font-size: rem(16);
            font-style: normal;
            font-weight: 400;
            line-height: rem(25);
        }
        h2 {
            margin-bottom: rem(50);
            @media (max-width: 767.9px) {
                margin-bottom: rem(20);
                font-size: rem(33);
                font-style: normal;
                font-weight: 400;
                line-height: rem(40);
            }
        }

        .li-title {
            color: #dadada;

            margin-bottom: rem(16);
        }

        .list-style-none {
            list-style-type: none;
        }

        .margin-left {
            margin-left: 0;
            padding-left: 5px;
        }

        &-second {
            ol {
                margin-bottom: rem(60);
                padding-left: 0;
                li {
                    list-style-type: none;
                    margin-bottom: rem(14);
                    ul {
                        padding-top: rem(16);
                        padding-left: rem(30);
                        li {
                            list-style-type: disc;
                            color: #8b8f92;
                        }
                    }
                }
            }

            ul {
                padding-top: rem(16);
                padding-left: rem(30);
                li {
                    list-style-type: disc;
                    color: #8b8f92;
                    margin-bottom: 0;
                }
            }
        }

        a {
            transition: all 0.25s ease;
            &:hover {
                color: #dadada;
            }
        }
    }
}

.offer {
    .container {
        max-width: rem(740);
        margin: 0 auto;
        padding: rem(72) 0 0;
        @media (max-width: 767.9px) {
            padding: 0 rem(30);
        }
    }
    &__wrapper {
        padding-top: rem(140);
        @media (max-width: 767.9px) {
            padding-top: rem(90);
        }
    }

    &__hero {
        &-desktop {
            display: block;
            width: fit-content;
            span {
                transform: rotate(-5deg);
                border-radius: 50px;
                background: #60d702;
                padding: rem(10) rem(50);
                margin-left: rem(-230);
                display: block;
                white-space: nowrap;
                font-family: $RobotoMono;
                font-size: rem(18);
                font-style: normal;
                font-weight: 500;
                line-height: rem(30);
                letter-spacing: rem(2.7);
                text-transform: uppercase;
                color: #090f11;
            }
            @media (max-width: 767.9px) {
                display: none;
            }
        }

        &-mobile {
            display: none;
            @media (max-width: 767.9px) {
                display: block;

                span {
                    width: fit-content;
                    border-radius: rem(50);
                    background: #60d702;
                    padding: rem(10) rem(10);
                    // margin-left: rem(-230);
                    display: block;
                    white-space: nowrap;
                    color: #090f11;
                    font-family: $RobotoMono;
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 500;
                    line-height: rem(21);
                    letter-spacing: rem(2.1);
                    text-transform: uppercase;
                    color: #090f11;

                    &:nth-child(1) {
                        transform: rotate(-7.3deg);
                        padding-left: rem(88);
                        padding-right: rem(38);
                        margin-left: rem(10);
                    }
                    &:nth-child(2) {
                        transform: rotate(11.5deg);
                        margin-top: rem(12);
                        padding-left: rem(35);
                        padding-right: rem(98);
                        margin-left: rem(10);
                    }
                    &:nth-child(3) {
                        transform: rotate(-3.9deg);
                        padding-left: rem(30);
                        padding-right: rem(35);
                        margin-left: rem(-53);
                        margin-top: rem(11);
                    }
                }
            }
        }
    }

    &__block {
        padding-top: rem(145);
        color: #8b8f92;
        font-size: rem(20);
        font-style: normal;
        font-weight: 400;
        line-height: rem(30);
        letter-spacing: rem(0.2);
        @media (max-width: 767.9px) {
            padding-top: rem(75);

            font-size: rem(16);
            font-style: normal;
            font-weight: 400;
            line-height: rem(25);
        }
        h2 {
            margin-bottom: rem(50);
            @media (max-width: 767.9px) {
                margin-bottom: rem(20);
                font-size: rem(33);
                font-style: normal;
                font-weight: 400;
                line-height: rem(40);
            }
        }
        p {
            margin-bottom: rem(60);
        }
        span {
            display: block;
            margin-bottom: rem(16);
        }
        .li-title {
            color: #dadada;

            margin-bottom: rem(16);
        }
        ol {
            margin-bottom: rem(60);
            padding-left: 0;
            li {
                list-style-type: none;
                margin-bottom: rem(14);
                ul {
                    padding-top: rem(16);
                    padding-left: rem(30);
                    li {
                        list-style-type: disc;
                        color: #8b8f92;
                    }
                }
            }
        }

        ul {
            padding-top: rem(16);
            padding-left: rem(30);
            li {
                list-style-type: disc;
                color: #8b8f92;
                margin-bottom: 8px;
            }
        }

        a {
            transition: all 0.25s ease;
            &:hover {
                color: #dadada;
            }
        }
        strong {
            color: #dadada;
        }
        &-second {
            padding-top: rem(60);
        }
        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
            border-width: 1px;
            padding: 5px 10px;
            @media (max-width: 767.9px) {
                font-size: rem(13);
            }
        }
    }
}

.footer {
    margin-top: auto;
    padding: 0 0 rem(20);

    font-family: $RobotoMono;
    font-size: rem(13);
    font-style: normal;
    font-weight: 400;
    line-height: rem(20);
    letter-spacing: rem(1.95);
    color: #8b8f92;
    text-transform: uppercase;
    @media (max-width: 767.9px) {
        padding: 0 0 rem(100);
    }
    .container {
        max-width: rem(1006);
        padding: 0;
        @media (max-width: 767.9px) {
            padding: 0 rem(30);
        }
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__desktop {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media (max-width: 767.9px) {
            display: none;
        }
    }
    &__row {
        &:nth-child(1) {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: rem(15) 0;
        }
        &:nth-child(2) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: rem(10) 0 rem(15);
        }
    }
    &__icons {
        display: flex;
        gap: 15px;
        margin-right: 70px;
        @media (max-width: 767.9px) {
            flex-direction: column;
            justify-content: flex-end;
            margin-right: 0;
        }
    }

    &__icon {
        width: rem(26);
        height: rem(22);
        @media (max-width: 767.9px) {
            width: rem(21);
            height: rem(17);
        }
        svg {
            width: 100%;
            height: 100%;
            path {
                transition: all 0.25s ease;
            }
        }

        &:hover svg path {
            fill: #dadada;
        }
    }

    &__mobile {
        display: none;

        @media (max-width: 767.9px) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    &__links {
        &-mail {
            display: flex;
            gap: rem(30);
            @media (max-width: 767.9px) {
                margin-bottom: 0;
                padding-bottom: rem(20);
            }
            a {
            }
        }
        @media (max-width: 767.9px) {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: rem(75);
        }
    }

    &__nav {
        display: flex;
        gap: rem(50);

        @media (max-width: 767.9px) {
            flex-direction: column;
            gap: rem(30);
        }
        &-item {
            transition: all 0.25s ease;

            &:hover {
                color: #dadada;
            }
        }
    }

    &__anchor-item {
        transition: all 0.25s ease;

        &:hover {
            color: #dadada;
        }
    }

    &__questions {
        display: flex;
        justify-content: space-between;

        &-links {
            max-width: rem(175);
            display: flex;
            flex-direction: column;
            gap: rem(30);
        }
    }
}

.politics-page {
    .form__element {
        display: none;
    }
}
.offer-page {
    .form__element {
        display: none;
    }
}

.form {
    padding: 0 15px;
    &-content {
        position: relative;
        color: #dadada;

        // &::before {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     bottom: 0;
        //     width: 100%;
        //     height: 2px;
        //     background-color: #090f11;
        // }
    }
    &__title {
        margin-bottom: 20px;

        font-family: $Inter;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        text-align: left;
        color: #dadada;

        @media (max-width: 767.9px) {
            margin-bottom: 8px;
            font-weight: 400;
            font-size: 35px;
            line-height: 1.28571;
            color: #dadada;
        }
    }
    .input-hidden {
        opacity: 0;
        position: absolute;
    }

    .f-input {
        width: 100%;
        padding-bottom: 8px;
        // margin-bottom: 29px;

        font-family: $RobotoMono;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        background: transparent;
        border: none;
        color: #dadada;
        border-bottom: 2px solid #2d3234;
        outline: none;

        @media (max-width: 767.9px) {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5625;
            letter-spacing: 0.01em;
            color: #dadada;
        }

        &::placeholder {
            color: #dadada;
        }

        &:focus {
            border-bottom: 2px solid #dadada;
        }

        &.telegram {
            position: relative;
            &::before {
                content: "для коммуникации";

                position: absolute;
                right: 0;
                bottom: 17px;

                font-family: $RobotoMono;
                font-weight: 400;
                font-size: 16px;
                text-align: right;
                color: #8b8f92;
            }
        }
    }

    .radio-block {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        border: 2px solid #2d3234;
        border-radius: 20px;
    }

    .f-radio {
        &-label {
            width: 256px;
            height: 125px;
            border-right: 2px solid #2d3234;
            cursor: pointer;

            @media (min-width: 768px) and (max-width: 1199.9px) {
                width: 50%;
                height: 125px;
            }

            @media (max-width: 767.9px) {
                width: 50%;
                height: 112px;
            }

            &:first-child {
                border-bottom: 2px solid #2d3234;
                .f-radio-text {
                    border-radius: 20px 0 0 0;

                    // &::before {
                    //     content: "";
                    //     z-index: 0;
                    //     position: absolute;
                    //     inset: 0;
                    //     width: 100%;
                    //     height: 100%;
                    //     background-image: url("../../../public/images/radio-top-mask.svg");
                    // }
                }
            }

            &:last-child {
                .f-radio-text {
                    border-radius: 0 0 0 20px;

                    // &::before {
                    //     content: "";
                    //     z-index: 0;
                    //     position: absolute;
                    //     inset: 0;
                    //     width: 100%;
                    //     height: 100%;
                    //     background-image: url("../../../public/images/radio-bottom-mask.svg");
                    // }
                }
            }
        }
        &-text {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 5px;
            height: 100%;
            padding: 20px 24px 10px 20px;
            background-color: #1d2325;
            // border-right: 2px solid transparent;
        }
        &-input {
            &:checked + .f-radio-text + .f-radio-description {
                display: flex;
                background-color: #1d2325;
            }
            &:checked + .f-radio-description {
                display: block;
            }

            &:not(:checked) + .f-radio-text {
                background-color: transparent;
                // border-right: 2px solid #2d3234;
                .f-radio-price {
                    opacity: .75;
                }
                .f-radio-mode {
                    opacity: .75;
                }
                &::before {
                    content: none;
                }
            }

            &:not(:checked) + .f-radio-description {
                display: none;
            }

            &:disabled + .f-radio-text {
                position: relative;
                overflow: hidden;
                // background: #1d2325;
                // width: 100%;

                &::before {
                    content: "Sold Out";
                    position: absolute;
                    top: 16px;
                    right: -8px;
                    left: auto;

                    padding: 3px 15px;
                    width: fit-content;
                    height: fit-content;

                    font-weight: 400;
                    font-size: 9px;
                    letter-spacing: 0.11em;
                    text-transform: uppercase;
                    color: #8b8f92;

                    background: #2d3234;
                    transform: rotate(15deg);
                }
            }
        }

        &-price {
            font-family: $RobotoMono;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.5;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            color: #dadada;
            @media (max-width: 767.9px) {
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5625;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: #dadada;
            }
        }

        &-mode {
            font-family: $RobotoMono;
            font-weight: 400;
            font-size: 15px;
            line-height: 1.33333;
            color: #8b8f92;
            @media (max-width: 767.9px) {
                font-weight: 400;
                font-size: 13px;
                color: #8b8f92;
            }
        }

        &-description {
            display: none;
            flex-direction: column;
            background-color: transparent;
            position: absolute;
            top: 0;
            right: 0;
            width: 253px;
            height: 100%;
            padding: 20px 24px 20px 20px;
            border-radius: 0 18px 18px 0;
            &::before {
                content: "";
                z-index: 0;
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background-image: url("../../../public/images/radio-right-mask.png");
            }
            @media (min-width: 768px) and (max-width: 1199.9px) {
                width: 50%;
                height: 100%;
            }
            @media (max-width: 767.9px) {
                width: 50%;
                height: 100%;
            }

            p {
                font-family: $RobotoMono;
                font-weight: 400;
                font-size: 15px;
                line-height: 1.33333;
                color: #8b8f92;

                @media (max-width: 767.9px) {
                    font-weight: 400;
                    font-size: 13px;
                    color: #8b8f92;
                }
            }
        }
    }

    .global-confirm-checkbox-block {
        margin-bottom: 8px;
        cursor: pointer;
        @media (max-width: 767.9px) {
            margin-bottom: 0;
        }
        input {
            // position: absolute;
            // z-index: -1;
            // opacity: 0;
            width: auto;
            display: inline-block;
            float: left;
            margin-top: 3px;
        }
        .checkbox-text {
            margin-left: 20px;
            display: block;

            font-weight: 400;
            font-size: 15px;
            line-height: 1.33333;
            color: #8b8f92;

            @media (max-width: 767.9px) {
                font-weight: 400;
                font-size: 13px;
                color: #8b8f92;
            }

            a {
                color: #2d8cff;
                text-decoration: none;
                transition: all 0.25s;

                &:hover {
                    color: #63a9fe;
                }
            }
        }
    }

    .submit {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 10px;
        margin-top: 24px;
        border-radius: 20px;
        padding: 30px 0px;
        width: 513px;
        height: 90px;

        background-color: #60d702;

        font-family: $RobotoMono;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.875;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #090f11;

        border: none;
        outline: none;
        @media (max-width: 767.9px) {
            border-radius: 20px;
            padding: 25px 30px;
            width: 100%;
            height: fit-content;

            font-weight: 500;
            font-size: 13px;
            line-height: 1.61538;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            color: #090f11;
        }
        @media (min-width: 768px) and (max-width: 1199.9px) {
            width: 100%;
        }
        &:disabled {
            background-color: #8b8f92;
            color: #090f11;
        }
    }

    &-error {
        padding-top: 8px;

        height: 29px;
        font-weight: 400;
        font-size: 15px;
        line-height: 1.33333;
        color: #ff0000;

        @media (max-width: 767.9px) {
            height: 16px;

            font-weight: 400;
            font-size: 11px;
        }

        &.checkbox {
            margin-left: 20px;
        }
    }
}
