@use "sass:math";
@mixin link {
	
}

$path: '../fonts/';

$Inter: "Inter",
Helvetica,
Arial,
sans-serif;

$RobotoMono: "RobotoMono",
Helvetica,
Arial,
sans-serif;

@mixin fontFaceInter($name, $src, $weight, $style) {
	$source: $path+$src;

	@font-face {
		font-family: $name;
		src: url($source + '.woff2') format('woff2'),
		url($source + '.woff') format('woff'),
		url($source + '.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}

@mixin fontFaceRobotoMono($name, $src, $weight, $style) {
	$source: $path+$src;

	@font-face {
		font-family: $name;
		src: url($source + '.woff2') format('woff2'),
		url($source + '.woff') format('woff'),
		url($source + '.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}

@include fontFaceInter('Inter', 'Inter-Regular', 400, normal);
@include fontFaceInter('Inter', 'Inter-ExtraLight', 100, normal);
@include fontFaceRobotoMono('RobotoMono', 'RobotoMono-Regular', 400, normal);

// TO %
@function prc($pxOne, $pxTwo) {
	$result: math.div($pxOne, $pxTwo) * 100%;
	@return $result;
}
// TO REM
@function rem($px) {
	$result: math.div($px, 16) + rem;
	@return $result;
}

// TO EM
@function em($px) {
	$result: math.div($px, 16) + em;
	@return $result;
}

// TO %
@function prc($pxOne, $pxTwo) {
	$result: math.div($pxOne, $pxTwo) * 100%;
	@return $result;
}
// TO REM
@function rem($px) {
	$result: math.div($px, 16) + rem;
	@return $result;
}

// TO EM
@function em($px) {
	$result: math.div($px, 16) + em;
	@return $result;
}